import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placegraph,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeUndo,
  placeRedo,
  placeStartOver,
  drawDash,
  placeTapeVert,
  placeTapeHori,
  placeMiddleText,
  placePlay,
  placeTest,
  placeBoldText,
  placeRightText,
  placeInput,
  alert
} from '../Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS////////////////////////////////
var graph =createSpace(-6,6,-5,7);
makeResponsive(graph);
//graph.suspendUpdate();
graph.options.layer['image'] =13;
graph.options.layer['circle'] =13;
graph.options.layer['chart'] =13;
graph.options.layer['sector'] =13;
graph.options.layer['line'] =13;
graph.options.layer['input'] =9;
graph.options.image.highlight=false;
/*********************GRAPH DIMENSIONS*******************/
const boundingBox = graph.attr.boundingbox;
const positionX = (boundingBox[2]+boundingBox[0])/2;
const positionY = (boundingBox[1]+boundingBox[3])/2;
const height = (boundingBox[1]-boundingBox[3])/2;
/**********************PUT AXES **********************/
var ax = createAxes(graph);
/***************** PUT AXES TITLE *******************/
//ax[1].setAttribute({visible:false, ticks:{visible:false}});
/****************PUT TITLE ****************************/
var question = placeQuestion(graph, 'Distance between Monkey Mo and Monkey Joe');
var comment = placeComment(graph, '');
var note = writeHTMLText(graph, positionX, positionY+height/2., 'You did not make a selection.');
note.setAttribute({visible:false});
/***********************SCORING *******************/
var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
var scoreCard = writeHTMLText(graph, positionX, positionY+1, function(){return 'Your Score ( &check; ): '+ yourScore+'/5'});
var missedCard = writeHTMLText(graph, positionX, positionY, function(){return 'Missed Questions ( ! ): '+ yourMissed+'/5'});
var wrongCard = writeHTMLText(graph, positionX, positionY-1, function(){return 'Wrong Answers ( x ): '+ yourWrong+'/5'});
scoreCard.setAttribute({visible:false});
missedCard.setAttribute({visible:false});
wrongCard.setAttribute({visible:false});
/*************************ANSWERS*********************/
var index_selected_answer = -1;
const XList = [2, -3, 4, -5, 3];
const YList = [4, 3, -2, 2, 0];
var lenArr = [6, 7, 4, 5, 8];
var fracArr = [3, 3, 3, 2, 2];
var Ptm = drawPoint(graph, XList[0], YList[0]);
Ptm.setAttribute({visible:false});
const answers = [['40', '10', '20', '30'], ['9', '18', '6', '21'],['6', '10', '20', '30'],['16', '8', '6', '12'], ['24', '20', '18', '16']];
const index_right_answer = [2, 6, 6, 7, 3];
/**/
var i=0; var k=0; var vis=false;
/**********************************************************************************/
var hint =writeHTMLText(graph, positionX, positionY, '<ol> <li> Tap on <i class="redoer ma-1"></i> to go to next step.</li> <br><li>Tap on <i class="tester ma-1"></i> to check your answer.</li><br><li>Tap on <i class="undoer ma-1"></i> button to start over.</li> </ol>');
hint.setAttribute({visible:false, color:'white'});
//////////////////////////////////////////////////////////////
var i=0; var k=0; var vis=false; var status =[0,0,0,0,0,0];
  var monkey = graph.create('image', ['/assets/monkeyMO.svg', [XList[0]-0.5,0], [1,1]], {fixed:true});
  var monkeyMo = graph.create('image', ['/assets/monkeyJo.svg', [YList[0]-0.5,0], [1,1]], {fixed:true});
  var seatMo = graph.create('point', [()=>monkeyMo.X()+0.5,0],{face:'triangledown', size:6, name:'', fillColor:'red',strokeColor:'black'});
  var seat = graph.create('point', [()=>monkey.X()+0.5,0],{face:'triangledown', size:6, name:'', fillColor:'yellow',strokeColor:'black'});
  //var test=placeImage(graph, '/assets/test.svg', 2., -2.5, 1, 0);
  ///////////////////////////GRAPHICS MODULES//////////////////
  var bck =placeWhite(graph);
  var show =placeBCircles(graph);
  var hide=placeCircles(graph);
  var check = placeChecks(graph);
  var cross = placeCross(graph);
  var exclaim = placeExclaim(graph);
  var pointers = placeFingers(graph);
  hide[0].setAttribute({visible:false});
  /***************************BUTTON MODULES******************/
  var test =placeTest(graph,'left');
  hoverMe(graph, test, 'Check Your Answer', -20, 0);
  /**/
  var next = placePlay(graph);
  hoverMe(graph, next, 'Next Question', 0, 0);
  /**/
  var redo = placeStartOver(graph);
  redo.setAttribute({visible:false});
  hoverMe(graph, redo, 'Start Over', 0, 0);
  /***************************************/
  var annoRight =placeMiddleText(graph, 0, 3, 'Yay, that is correct');
  annoRight.setAttribute({visible:false, color:'green'});
  var annoWrong =placeMiddleText(graph, 0, 3, 'Nope, that is not correct');
  annoWrong.setAttribute({visible:false, color:'red'});
  /*********************************************/
  placeRightText(graph, -0.25, -2., 'Distance between Mo and Joe is =');
  function monkeyHop(){
      monkey.moveTo([XList[i]-0.5, 0], 10);
      monkeyMo.moveTo([YList[i]-0.5, 0], 10);
  }
  function monkeyDown(){
      monkey.moveTo([monkey.X(), 0], 10);
      monkeyMo.moveTo([monkeyMo.X()-0.5, 0], 10);
  }
  function jump()
  {
      if(input.Value() == monkey.X()+0.5)
      {
          monkey.moveTo([monkey.X(), 1], 10);
          setTimeout(function(){monkeyDown(); }, 200);
          alert(graph, annoRight);
          return ;
      }
      else
      {
          alert(graph, annoWrong);
      }
  }
  function hop()
  {
    monkey.moveTo([XList[i]-0.5, 1], 100);
    monkeyMo.moveTo([YList[i]-0.5, 1], 100);
    setTimeout(function(){monkeyHop(); }, 300);
    return ;
  }
  var input = placeInput(graph, 0, -2, " ");
////////////////////////////////////////
next.on('down', function(){
  note.setAttribute({visible:false});
  console.log(input.Value(), index_right_answer[i]);
  if(i<=XList.length-1)
  {
    if(input.Value() == index_right_answer[i] && yourTotal<XList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
      //  ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(input.Value() != index_right_answer[i] && yourTotal<XList.length && index_selected_answer != null)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
      //  ansArray[index_right_answer[i]].setAttribute({color:'green'});
      //  ansArray[index_selected_answer].setAttribute({color:'red'});
		    yourWrong = yourWrong+1;
      }
	  else if (input.Value() == null && yourTotal<XList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
		    yourMissed = yourMissed+1;
	  }
	  yourTotal = yourScore + yourWrong + yourMissed;
  }
  if(i==XList.length-1)
  {
  bck.setAttribute({opacity:1});
  scoreCard.setAttribute({visible:true});
  missedCard.setAttribute({visible:true});
  wrongCard.setAttribute({visible:true});
  redo.setAttribute({visible:true});
//  ansArray.forEach(function(item){item.setAttribute({visible:false})});
  question.setAttribute({visible:false});
  input.setAttribute({visible:false});
  }
  else{
    scoreCard.setAttribute({visible:false});
    missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
    i=i+1;
  }
  hide[i].setAttribute({visible:false});
  hint.setAttribute({visible:false});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  index_selected_answer = -1;
  /////////////////////////ALL CHANGES GO HERE////////////////////////
  hop();
  ///////////////////////////////////////////////////////////////////
});
/////////////////////////////////////////////////////////
test.on('down', function()
{
  note.setAttribute({visible:false});
  jump();
  if(i<=XList.length-1)
  {
    if(input.Value() == index_right_answer[i] && yourTotal<XList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        //ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(input.Value() != index_right_answer[i] && yourTotal<XList.length)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        //ansArray[index_right_answer[i]].setAttribute({color:'green'});
        //ansArray[index_selected_answer].setAttribute({color:'red'});
      }
	  else if (input.Value == null && yourTotal<XList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
        note.setAttribute({visible:true});
	  }
  }
});
/////////////////////////////////////////////////////////////////
redo.on('down', function(){
	//Hiding all blue circles
	  hide.forEach(function(item){item.setAttribute({visible:true})});
    hide[0].setAttribute({visible:false});
  //Hiding all check marks
    check.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all cross marks //
    cross.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all exclaimation marks
	  exclaim.forEach(function(item){item.setAttribute({visible:false})});
  ///////////////resetting scores//////////////////
    note.setAttribute({visible:false});
	  yourScore=0;
	  yourMissed=0;
	  yourWrong=0;
	  yourTotal=0;
	  scoreCard.setAttribute({visible:false});
	  missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
  /////////////////////////////////////////////
	  i=0;
	  bck.setAttribute({opacity:0});
	  pointers.forEach(function(item){item.setAttribute({visible:false})});
    //ansArray.forEach(function(item){item.setAttribute({visible:true, color:'grey'})});
	  question.setAttribute({visible:true});
    comment.setAttribute({visible:true});
	  hint.setAttribute({visible:false});
    input.setAttribute({visible:true});
	  index_selected_answer = -1;
    redo.setAttribute({visible:false});
    ////////////////////////ALL CHANGES GO HERE/////////////
    monkey.moveTo([XList[0]-0.5,0]);
    monkeyMo.moveTo([YList[0]-0.5,0]);
    ////////////////////////////////////////////////////////
    //alpha.setAttribute({visible:true});
    //beta.setAttribute({visible:true});
});
/////////////////////////////////////////////////////////////////////
  var edliy = placeLogo(graph);
  hoverMe(graph, edliy, 'Tap to see a hint', 0, 0);
  edliy.on('down',
  function(){
    k = toggle(k);
  	vis = toggleTF(vis);
    bck.setAttribute({opacity:k});
  	scoreCard.setAttribute({visible:false});
  	missedCard.setAttribute({visible:false});
  	wrongCard.setAttribute({visible:false});
    hint.setAttribute({visible:vis});
    input.setAttribute({visible:!vis});
  	question.setAttribute({visible:!vis});
  	//ansArray.forEach(function(item){item.setAttribute({visible:!vis})});
    note.setAttribute({visible:false});
  });
  //graph.create('text', [-5, 1, function(){return "INPUT VALUE =  "+ input.Value()}], { CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}});

  //var input = graph.create('input', [-4, -2, '0', 'Monkey is at ='], {CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}});

  //var input = graph.create('text', [1, 3, '<input type="text" name="txt" value=" " onchange="jump()">']);
  //graph.unsuspendUpdate();
},
}
export default Boxes;
